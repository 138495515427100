import { ChamadosService } from './../chamados.service';
import { Component, OnInit } from '@angular/core';
import { usuarioLogado } from '../app.component';


@Component({
  selector: 'app-chamados',
  templateUrl: './chamados.component.html',
  styleUrls: ['./chamados.component.css']
})
export class ChamadosComponent implements OnInit {

  chamados: Array<any> = new Array<any>();
  chamadosBusca: Array<any> = new Array<any>();

  chamadosTimer;

  chamadosAbertoTI: Array<any> = new Array<any>();
  chamadosAbertoESCRITORIO: Array<any> = new Array<any>();
  chamadosAbertoRH: Array<any> = new Array<any>();
  chamadosAbertoFINANCEIRO: Array<any> = new Array<any>();
  chamadosAbertoMKT: Array<any> = new Array<any>();
  chamadosAbertoEXE: Array<any> = new Array<any>();
  chamadosAbertoDEV: Array<any> = new Array<any>();


  chamadoOpen: any = "";
  chamadoResolve: any;


  obsNewChamado: any = "";
  stsNewChamado: any = 3;
  imgNewChamado: any = '';

  novoSetor: any = "";

  iniciouAtendimento: boolean = false;

  textBusca: any = "";

  formChamado: Object = {
    setor: '',
    cod_status: 0,
    loja: '',
    venda: '',
    descricao: '',
    imagem: '',
  }

  fileToUpload: File = null;

  constructor(public chamadosSrv: ChamadosService) {
    //this.chamadosTimer = setInterval(()=>{
    this.buscarChamados();
    //}, 2000);
    console.log('-----111', usuarioLogado);
  }



  listaChamados(lista, tit) {
    this.chamadoOpen = tit;
    this.chamados = lista;
  }
  novoChamado() {
    this.formChamado = {
      setor: '',
      situacao: 0,
      loja: '',
      documento: '',
      descricao: '',
      imagem: '',
    }
  }

  async changeSetor() {
    if (this.chamadoResolve && this.chamadoResolve.setor && this.novoSetor) {
      if (confirm('Você tem certeza que deseja transferir de ' + this.chamadoResolve.setor + ' para ' + this.novoSetor + "?")) {
        await (await this.chamadosSrv.transfereChamado(this.chamadoResolve.cod_chamado, this.novoSetor)).subscribe(data => {
          this.chamadoResolve = data;
          this.chamadoResolve.setor = this.novoSetor;
          // await this.buscarChamados();
          // this.abrirChamado(this.chamadoResolve);
          this.novoSetor = null;
          this.buscarChamados();
        });


      } else {
        this.novoSetor = null;
      }
    }
  }

  async salvarNovoChamado() {
    console.log(this.formChamado);

    if (this.formChamado['setor'] == '') {
      alert('Informe um setor!');
    } else if (this.formChamado['situacao'] == '' || this.formChamado['situacao'] == '0') {
      alert('Informe um Status');
    } else if (this.formChamado['loja'] == '') {
      alert('Informe uma loja');
    } else if (this.formChamado['descricao'] == '') {
      alert('Informe uma descrição');
    } else {

      let res = (await this.chamadosSrv.storeChamado({ ...this.formChamado, usuario: usuarioLogado.nome, cod_usuario: usuarioLogado.cod_usuario })).subscribe(ret => {
        console.log('----', ret);
      });


      console.log('===', res);

      this.formChamado = {
        setor: '',
        cod_status: 0,
        loja: '',
        documento: '',
        descricao: '',
        imagem: '',
      }
    }
  }

  async handleFileInput(files: FileList, objToAdd = 0) {
    this.fileToUpload = files.item(0);
    try {
      this.chamadosSrv.upload(this.fileToUpload).subscribe(res => {
      });
      if (objToAdd == 1) {
        this.imgNewChamado = files.item(0).name;
      } else {
        this.formChamado['imagem'] = files.item(0).name;
      }
    } catch (err) {
      console.log('errrrr', err);
    }
  }
  buscar() {
    if (this.textBusca == null || this.textBusca == "") {
      alert("Favor informar uma busca!");
      return;
    }


    this.chamadosSrv.getChamados(this.textBusca, true).subscribe((data: Array<any>) => {
      this.chamadosBusca = data;
    });
  }

  chamadosDeHoje() {
    this.chamadosSrv.getChamados(this.textBusca, true, true).subscribe((data: Array<any>) => {
      this.chamadosBusca = data;
    });
  }

  abrirChamado(chamado) {
    this.novoSetor = null;
    this.chamadoResolve = chamado;
    console.log("CHAMADO", chamado);
    this.iniciouAtendimento = false;

    if (chamado.usuario_aberto != null && chamado.usuario_aberto != "") {
      if (chamado.usuario_aberto == usuarioLogado.nome) {
        alert("Você já está executando este chamado! Finalize!");
        this.iniciouAtendimento = true;

      }
      else {
        alert("Este chamado já está sendo atendido por: " + chamado.usuario_aberto);

      }
    }
    this.chamadosSrv.getChamados(chamado.cod_chamado, false).subscribe((data: Array<any>) => {
      this.chamadoResolve.registros = data;
    });
  }

  mudaStsChamado(op) {
    if (op == "2") {
      this.iniciouAtendimento = true;
    }
    else {
      this.iniciouAtendimento = false;
    }
    this.chamadosSrv.registraStatus(this.chamadoResolve.cod_chamado, op, op == "2" ? "" : op == "3" ? "OK" : "", this.imgNewChamado).subscribe((data) => {
      this.chamadoResolve = data;
    });
  }

  addRegistroChamado() {
    if (this.chamadoResolve == null) {
      alert("Favor abrir um chamado!");
      return;
    }
    if (this.obsNewChamado == null || this.obsNewChamado == "") {
      alert("Favor informar uma observação!");
      return;
    }
    if (this.stsNewChamado == null || this.stsNewChamado == "") {
      alert("Favor informar um status!");
      return;
    }

    this.chamadosSrv.registraStatus(this.chamadoResolve.cod_chamado, this.stsNewChamado, this.obsNewChamado, this.imgNewChamado).subscribe((data) => {
      console.log(data);
      this.chamadoResolve = data;
      this.obsNewChamado = "";
      this.imgNewChamado = "";
      this.iniciouAtendimento = false;
    });

  }


  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then();
  }

  executando = false;

  buscarChamados() {
    if (this.executando)
      return;

    this.executando = true;

    this.chamadosSrv.getChamados("", false).subscribe((data: Array<any>) => {
      this.chamadosAbertoESCRITORIO = data.filter(x => x.setor == "ESCRITORIO");
      this.chamadosAbertoTI = data.filter(x => x.setor == "TI");
      this.chamadosAbertoRH = data.filter(x => x.setor == "RH");
      this.chamadosAbertoMKT = data.filter(x => x.setor == "MKT");
      this.chamadosAbertoFINANCEIRO = data.filter(x => x.setor == "FINANCEIRO");
      this.chamadosAbertoFINANCEIRO = data.filter(x => x.setor == "FINANCEIRO");
      this.chamadosAbertoDEV = data.filter(x => x.setor == "DEV");
      //console.log(data);
      if (this.chamados != null && this.chamados.length > 0) {
        let con = data.filter(x => this.chamadoOpen == 'Sendo Executado' ? x.usuario_aberto != "" : x.setor == this.chamados[0].setor);
        if (con != null) {
          this.chamados = con;
        }
      }

      this.executando = false;

      this.delay(5000).then(any => {
        this.buscarChamados();
      });

    });
  }

  ngOnInit() {
  }

}
